<template>
    <div id="deliveryAdress2">
        <span class="text family-bold bold size-20 center">Escoge el tipo de entrega de tu pedido</span>
        <div class="cont-btn-options">
            <div class="btn-option border-left" @click="trueDomiT ()" :class="{'borderT' : !rTienda, 'active': domiT}">
                <span class="text semi-bold size-18">Domicilio</span>
            </div>
            <div v-if="rTienda" class="btn-option border-right" :class="{'active': !domiT}" @click="recogerTienda ()">
                <span class="text semi-bold size-18 center">Recoger en tienda</span>
            </div>
        </div>
        <outCoverage v-if="show == 3" @close="$parent.close()" @option="changeShow ($event)"></outCoverage>
        <coverage v-if="show == 2" @close="$parent.close()"  @option="changeShow ($event)" @dataCoverage="emitRestarantCoverage($event)" @changeAddress="changeAddress($event)"></coverage>
        <actualAddress v-if="show == 1" @close="$parent.close()" @option="changeShow ($event)" @dataCoverage="emitRestarantCoverage($event)" @changeAddress="changeAddress($event)"></actualAddress>
    </div>
</template>
<script>
import coverage from '@/components/maps/index2';
import actualAddress from '@/components/templateFood/coverageHome/actualAddress2';
import outCoverage from '@/components/templateFood/coverageHome/outCoverage2';
import LocalStorage from 'store';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  components: { coverage, actualAddress, outCoverage },
  data () {
    return {
      show: 1,
      rTienda: true,
      domiT: true
    };
  },
  computed: {
    ...mapGetters([
      'getVendor',
      'globalLoader'
    ])
  },
  mounted () {
    const thes = this;
    if (thes.getVendor) {
      if (thes.getVendor && thes.getVendor.length === undefined) {
        if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
          thes.rTienda = true;
        } else {
          thes.rTienda = false;
        }
      } else {
        setTimeout(function () {
          if (thes.getVendor && thes.getVendor.length === undefined) {
            if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
              thes.rTienda = true;
            } else {
              thes.rTienda = false;
            }
          } else {
            setTimeout(function () {
              if (thes.getVendor && thes.getVendor.length === undefined) {
                if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                  thes.rTienda = true;
                } else {
                  thes.rTienda = false;
                }
              } else {
                setTimeout(function () {
                  if (thes.getVendor && thes.getVendor.length === undefined) {
                    if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                      thes.rTienda = true;
                    } else {
                      thes.rTienda = false;
                    }
                  } else {
                    setTimeout(function () {
                      if (thes.getVendor && thes.getVendor.length === undefined) {
                        if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                          thes.rTienda = true;
                        } else {
                          thes.rTienda = false;
                        }
                      }
                    }, 200);
                  }
                }, 200);
              }
            }, 200);
          }
        }, 200);
      }
    } else {
      setTimeout(function () {
        if (thes.getVendor && thes.getVendor.length === undefined) {
          if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
            thes.rTienda = true;
          } else {
            thes.rTienda = false;
          }
        } else {
          setTimeout(function () {
            if (thes.getVendor && thes.getVendor.length === undefined) {
              if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                thes.rTienda = true;
              } else {
                thes.rTienda = false;
              }
            } else {
              setTimeout(function () {
                if (thes.getVendor && thes.getVendor.length === undefined) {
                  if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                    thes.rTienda = true;
                  } else {
                    thes.rTienda = false;
                  }
                } else {
                  setTimeout(function () {
                    if (thes.getVendor && thes.getVendor.length === undefined) {
                      if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                        thes.rTienda = true;
                      } else {
                        thes.rTienda = false;
                      }
                    }
                  }, 200);
                }
              }, 200);
            }
          }, 200);
        }
      }, 200);
    }
    if (LocalStorage.get('tienda_Coverage_client')) {
      this.domiT = true;
    } else {
      this.domiT = false;
    }
  },
  methods: {
    changeShow (id) {
      this.show = id;
    },
    emitRestarantCoverage (data) {
      this.$emit('setDataCoverage', data);
      this.$parent.close();
    },
    changeAddress (address) {
      this.$emit('setAddress', address);
      this.domiT = true;
      this.show = 1;
      var popup = document.getElementById('myPopup');
      var tri = document.getElementById('tri');
      popup.classList.toggle('show');
      tri.classList.toggle('show2');
    },
    recogerTienda () {
      this.domiT = false;
      LocalStorage.remove('address_Coverage_client');
      LocalStorage.remove('center_Coverage_client');
      LocalStorage.set('tienda_Coverage_client', 'recogerTienda');
      this.show = 1;
      this.$emit('tienda', true);
      var popup = document.getElementById('myPopup');
      popup.classList.toggle('show');
    },
    isAvail (times) {
      const dayOfWeek = parseInt(this.$moment().format('d'));
      const timeCurrent = this.$moment().format('HH:mm');
      if (times.dayTimes !== undefined) {
        const data = _.find(times.dayTimes, { day: dayOfWeek });
        if (data) {
          if (data.hasAttention === 1) {
            if (timeCurrent >= data.open && timeCurrent <= data.close) {
              times.closed = 0;
            } else {
              times.closed = 1;
              times.open = 'Abre a las ' + this.$moment(new Date('1970-01-01 ' + data.open)).format('hh:mm A');
              times.isPrograming = 1;
            }
          } else {
            times.closed = 1;
            times.open = 'Hoy no abre';
            times.isPrograming = 0;
          }
        }
      } else {
        times.closed = 1;
        times.open = 'Hoy no abre';
        times.isPrograming = 0;
      }
      return times;
    },
    trueDomiT () {
      this.domiT = true;
    }
  }
};
</script>
<style lang="scss">
    #deliveryAdress2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px 10px;
        height: 100%;
        .cont-btn-options{
        display: flex;
        margin:30px 0 20px 0;
        width: 90%;
        .btn-option{
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #E6E6E6;
            flex: 1;
            cursor: pointer;
            user-select: none;
            .text{
                color: #000000;
            }
            &.border-left{
                border-radius: 7px 0px 0px 7px;
            }
            &.border-right{
                border-radius: 0px 7px 7px 0px;
            }
            &.borderT {
                border-radius: 7px 7px 7px 7px;
            }
            &.active{
                box-shadow: 0px 3px 6px var(--footerTextColor);
                background: var(--footerBackground);
                .text{
                    color: var(--footerTextColor);
                }
            }
            &.disabled{
                cursor: not-allowed;
                .text{
                    color: #9B9B9B;
                }
            }
        }
    }
    }
</style>
