<template>
    <div id="deliveryAdress" :class="{'less-gap': show ===2}">
      <div v-if="show===2" class="arrowBack" @click="()=>show = 1"></div>
        <span class="text segoe family-bold bold size-18 center" style="width: 222px;" v-if="show==1 || show===3">Escoge el tipo de entrega de tu pedido</span>
        <span class="text segoe family-bold bold size-18 center" style="width: 222px; padding-bottom: 20px;" v-if="(show===2 && !showMap)">Añade tu nueva dirección de envío </span>
        <div class="btn-new-address-other" v-if="(show===2 && !showMap)">
            <i class="fas fa-search" style="width: 16px;height: 16px; margin-right:15px; color: white;"></i>
            <span class="text semi-bold size-14">Elegir una dirección diferente</span>
        </div>
        <div class="cont-btn-options" v-if="show==1 || show===3">
            <div class="btn-option border-left" @click="trueDomiT ()" :class="{'borderT' : !rTienda, 'active': domiT}">
                <span class="text semi-bold">Domicilio</span>
            </div>
            <div v-if="rTienda" class="btn-option border-right" :class="{'active': !domiT}" @click="recogerTienda ()">
                <span class="text semi-bold center">Recoger en tienda</span>
            </div>
        </div>
        <outCoverage v-if="show == 3" @close="$parent.close()" @option="changeShow ($event)"></outCoverage>
        <coverage :vendorId ="idV" v-if="show == 2" @close="$parent.close()" @clicked-map="clickedMap" @option="changeShow ($event)" @dataCoverage="emitRestarantCoverage($event)" @changeAddress="changeAddress($event)"></coverage>
        <actualAddress :vendorId ="idV" v-if="show == 1" @close="$parent.close()" @option="changeShow ($event)" @dataCoverage="emitRestarantCoverage($event)" @changeAddress="changeAddress($event)"></actualAddress>
    </div>
</template>
<script>
import coverage from '@/components/maps/index';
import actualAddress from '@/components/templateFood/coverageHome/actualAddress';
import outCoverage from '@/components/templateFood/coverageHome/outCoverage';
import LocalStorage from 'store';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  props: ['idV'],
  components: { coverage, actualAddress, outCoverage },
  data () {
    return {
      show: 1,
      rTienda: true,
      domiT: true,
      showMap: false
    };
  },
  computed: {
    ...mapGetters([
      'getVendor',
      'globalLoader'
    ])
  },
  mounted () {
    const thes = this;
    if (thes.idV && thes.getVendor) {
      if (thes.getVendor && thes.getVendor.length === undefined) {
        if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
          thes.rTienda = true;
        } else {
          thes.rTienda = false;
        }
      } else {
        setTimeout(function () {
          if (thes.getVendor && thes.getVendor.length === undefined) {
            if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
              thes.rTienda = true;
            } else {
              thes.rTienda = false;
            }
          } else {
            setTimeout(function () {
              if (thes.getVendor && thes.getVendor.length === undefined) {
                if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                  thes.rTienda = true;
                } else {
                  thes.rTienda = false;
                }
              } else {
                setTimeout(function () {
                  if (thes.getVendor && thes.getVendor.length === undefined) {
                    if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                      thes.rTienda = true;
                    } else {
                      thes.rTienda = false;
                    }
                  } else {
                    setTimeout(function () {
                      if (thes.getVendor && thes.getVendor.length === undefined) {
                        if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                          thes.rTienda = true;
                        } else {
                          thes.rTienda = false;
                        }
                      }
                    }, 200);
                  }
                }, 200);
              }
            }, 200);
          }
        }, 200);
      }
    } else {
      setTimeout(function () {
        if (thes.idV && thes.getVendor && thes.getVendor.length === undefined) {
          if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
            thes.rTienda = true;
          } else {
            thes.rTienda = false;
          }
        } else {
          setTimeout(function () {
            if (thes.idV && thes.getVendor && thes.getVendor.length === undefined) {
              if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                thes.rTienda = true;
              } else {
                thes.rTienda = false;
              }
            } else {
              setTimeout(function () {
                if (thes.idV && thes.getVendor && thes.getVendor.length === undefined) {
                  if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                    thes.rTienda = true;
                  } else {
                    thes.rTienda = false;
                  }
                } else {
                  setTimeout(function () {
                    if (thes.idV && thes.getVendor && thes.getVendor.length === undefined) {
                      if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' })) {
                        thes.rTienda = true;
                      } else {
                        thes.rTienda = false;
                      }
                    }
                  }, 200);
                }
              }, 200);
            }
          }, 200);
        }
      }, 200);
    }
  },
  methods: {
    clickedMap (state) {
      this.showMap = state;
    },
    changeShow (id) {
      this.show = id;
    },
    emitRestarantCoverage (data) {
      this.$emit('setDataCoverage', data);
      this.$parent.close();
    },
    changeAddress (address) {
      this.$emit('setAddress', address);
      this.$parent.close();
    },
    recogerTienda () {
      this.domiT = false;
      if (!this.idV) {
        LocalStorage.set('tienda_Coverage_client', 'recogerTienda');
        const instance = this;
        this.$http.get('/marketplace/client/vendors').then(({ data }) => {
          Object.values(data.data).map(info => {
            instance.isAvail(info);
          });
          this.$emit('setDataCoverage', data.data);
          this.$store.commit('setGlobalLoader', false);
          this.$parent.close();
        }).catch(err => {
          this.$store.commit('setGlobalLoader', false);
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$parent.listErrors(err.request.response, err.request.status),
            position: 'is-top',
            type: 'is-danger'
          });
        });
      } else {
        LocalStorage.remove('address_Coverage_client');
        LocalStorage.remove('center_Coverage_client');
        LocalStorage.set('tienda_Coverage_client', 'recogerTienda');
        this.$emit('setTienda', true);
        this.$parent.close();
      }
    },
    isAvail (times) {
      const dayOfWeek = parseInt(this.$moment().format('d'));
      const timeCurrent = this.$moment().format('HH:mm');
      if (times.dayTimes !== undefined) {
        const data = _.find(times.dayTimes, { day: dayOfWeek });
        if (data) {
          if (data.hasAttention === 1) {
            if (timeCurrent >= data.open && timeCurrent <= data.close) {
              times.closed = 0;
            } else {
              times.closed = 1;
              times.open = 'Abre a las ' + this.$moment(new Date('1970-01-01 ' + data.open)).format('hh:mm A');
              times.isPrograming = 1;
            }
          } else {
            times.closed = 1;
            times.open = 'Hoy no abre';
            times.isPrograming = 0;
          }
        }
      } else {
        times.closed = 1;
        times.open = 'Hoy no abre';
        times.isPrograming = 0;
      }
      return times;
    },
    trueDomiT () {
      this.domit = true;
    }
  },
  watch: {
    show () {
      if (this.show === 1) {
        this.showMap = false;
      }
    },
    showMap (value) {
      if (value) {
        document.getElementsByClassName('modalCoverage')[0].classList.add('isActiveMap');
        return;
      }
      document.getElementsByClassName('modalCoverage')[0].classList.remove('isActiveMap');
    }
  }
};
</script>
<style lang="scss">
    #deliveryAdress{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        // padding: 20px 10px;
        height: 100%;
        &.less-gap {
          gap: 10px;
        }
        .btn-new-address-other{
          width: 330px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #324045;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 6px;
          border: solid 1px #324045;
          // cursor: pointer;
          svg{
            path{
            fill: white;
            }

          }
          .text{
              color: #fff;
          }
        }
        .cont-btn-options{
        display: flex;
        gap: 10px;
        // margin:30px 0 20px 0;
        width: 90%;
        .btn-option{
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #E6E6E6;
            flex: 1;
            border-radius: 7px;
            cursor: pointer;
            user-select: none;
                box-shadow: 0px 3px 6px var(--footerTextColor);
                background: white;
                border: solid 1px #324045;
                .text{
                    color: #324045;
                    font-weight: 600;
                    font-size: 14px;
                }
            &.borderT {
                border-radius: 7px 7px 7px 7px;
            }
            &.active{
                background:#324045 ;
                border:solid 1px var(--footerBackground);
                .text{
                    color: white;
                }
            }
            &.disabled{
                cursor: not-allowed;
                .text{
                    color: #9B9B9B;
                }
            }
        }
    }
    }
    .arrowBack{
      width: 10px;
      height: 10px;
      position: absolute;
      top: 18px;
      left: 18px;
      border-left: solid 2px black;
      border-top: solid 2px black;
      border-radius: 2px;
      transform: rotate(-45deg);
      cursor: pointer;
    }
</style>
