<template>
    <div id="nav" :style="{'background-image': 'url(' + getVendor.navBarImg + ')'}" :class="{'no-padding':$route.path.split('/')[1] == 'order', 'modeMenuNavBar': getVendor.modeMenu == 1}" v-if="$route.path.split('/')[2] != 'menu-digital' && $route.path.split('/')[2] != 'order'">
    <div class="flexCenter" style="height: 100%;">
      <!-- <img class="banner-logo" src="@/assets/image/logoFood.svg" @click="home" v-if="$route.path.split('/')[2] !== 'restaurant' &&  $route.path.split('/')[2] !== 'order' && Object.keys(getVendor).length == 0"> -->
      <!-- <div
          :style="{
          'background-image': 'url(' + getVendor.logo + ')',
          'background-size': 'cover'
        }"
        style="width: 200px; height: 40px;"
        @click="home"
        class="banner-logo logoVendor"
        v-if="Object.keys(getVendor).length > 0" @error="errorImgVendor(this)"
        >
        </div> -->
      <img class="banner-logo logoVendor" :src="getVendor.logo" @click="home" v-if="Object.keys(getVendor).length > 0" @error="errorImgVendor(this)">
      <a id="backDesktop" href="javascript:void(0)" class="btn-action-home" v-if="$route.path.split('/')[2] != 'menu-digital' && $route.path != '/' && $route.path.split('/')[2] != 'orderQuery' && getVendor.modeMenu == 0" @click="returnGo"><span class="text bold segoe size-12" style="color: var(--buttonTextColor);">Volver</span></a>
       <div
        class="button-store-back-2 hide-cellphone"
        v-if="$route.path.split('/')[2] == 'orderQuery'"
      >
        <button class="button-store-back-design-2" style="background-color: var(--buttonBackground); color: var(--buttonTextColor)" @click="goRestaurant">
          <span> VOLVER</span>
        </button>
      </div>
    </div>
      <div id="backMobile" href="javascript:void(0)" v-if="$route.path.split('/')[2] != 'menu-digital' && $route.path != '/' && $route.path.split('/')[2] != 'orderQuery' && getVendor.modeMenu == 0" @click="returnGo">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="container-flex" v-if="$route.path.split('/')[2] == 'restaurant'" style="display: none;">
        <div class="contUbication">
          <div class="pointerA" @click="openPopAddress()">
            <i class="icon-mks icon_pointerMap icon_map"></i>
          </div>
          <span @click="openPopAddress()" class="text segoeBold size-16 pointer ellipsis-150" style="color: var(--mapTextColor);">{{deliveryAddress}}</span>
          <i @click="openPopAddress()" class="icon-mks icon_arrowDown"></i>
          <div class="popuptext" id="myPopup">
            <deliveryAddress @tienda="changeDeliveryAddress()" @setAddress="deliveryAddress = $event" >
            </deliveryAddress>
          </div>
          <div id="tri" class="recr"></div>
        </div>
      </div>
      <div class="flex" :class="{'modeMenu': getVendor.modeMenu == 1}" v-if="$route.path.split('/')[2] == 'restaurant'">
        <div id="searchDesktop" style="position: relative; text-align-last:left;">
          <input class="input-nav" placeholder="Buscar tu producto" type="text" v-model="query" @keydown.esc="query = ''">
          <div class="queryCont" v-if="query == ''">
            <i class="icon-mks icon_search black mouse"></i>
          </div>
          <div class="queryCont" v-if="query != ''" @click="query = ''">
          <i class="icon-mks icon_close black mouse"></i>
          </div>
        </div>
        <a class="btn-card" style="background:#292525" id="cartShow" @click="showNotification" v-if="$route.path.split('/')[2] == 'restaurant' && getVendor.modeMenu == 1">
          <i class="icon-mks icon_car_menu white center-absolute" id="cartShow"></i>
          <span class="items-cart" style="background:#CDD500;color:#000;text-align-last: center;font-weight:100">{{ cartItem }}</span>
        </a>
      </div>
      <div class="container-flex" v-if="$route.path == '/'">
        <div style="position: relative; text-align-last:left;">
          <input class="input-nav" placeholder="Buscar restaurante" type="text" v-model="find" @keydown.esc="find = ''">
          <i class="icon-mks icon_search search-position gray mouse" v-if="find == ''"></i>
          <i class="icon-mks icon_close search-position gray mouse" v-if="find != ''" @click="find = ''"></i>
        </div>
        <a class="btn-card" id="cartShow" @click="showNotification" v-if="$route.path.split('/')[2] == 'restaurant' ">
          <i class="icon-mks icon_card_market black center-absolute iconColor" id="cartShow"></i>
          <span class="items-cart">{{ cartItem }}</span>
        </a>
      </div>
      <div class="flex flex-mobile" :class="{'modeCarta': getVendor.modeMenu == 1}" v-if="$route.path.split('/')[2] != 'orderQuery'">
        <div class="btn-home order" style="position:relative;" @click="queryOrder()" v-if="getVendor.modeMenu == 0">
          <i class="far fa-window-maximize iconColor"></i>
          <i class="fas fa-search iconColor" style="position: absolute;bottom: 10px;right: 12px;font-size: 10px;"></i>
        </div>
        <div class="modeCarta-conve-mesero" v-if="false">
          <span class="text bold segoe size-16" style="color: var(--buttonTextColor);"><i style="color: var(--buttonTextColor);margin-right:5px;margin-bottom: 2px;" class="fas fa-sort-down"></i>Convenciones</span>
          <span class="text bold segoe size-16" style="color: var(--buttonTextColor);display:flex"><i style="background: var(--buttonTextColor);margin-right:5px" class="icon-mks icon_mesero"></i>Llamar al mesero</span>
        </div>
          <a class="btn-card" id="cartShow" @click="showNotification" v-if="$route.path.split('/')[2] == 'restaurant' && getVendor.modeMenu == 0">
            <i class="icon-mks icon_card_market black center-absolute iconColor" id="cartShow"></i>
            <span class="items-cart">{{ cartItem }}</span>
          </a>
      </div>
      <b-message id="msg-order" v-click-outside="queryOrder" :active.sync="search" aria-close-label="Close message">
        <div class="point-msg"></div>
        <b-input v-model="orderId"></b-input>
        <div @click="$router.push('/'+orderId+'/orderQuery'),search=false" class="btn-consultar"><i style="color:white;margin-right:5px" class="fas fa-search iconColor"></i><p class="text small white">Consultar</p></div>
        <p class="text normal center">Ingresa Order ID, para conocer el estado de tu pedido</p>
      </b-message>
      <b-message id="msg-search" class="search" v-click-outside="searchMobileF" :active.sync="searchMobile" aria-close-label="Close message">
        <div class="point-msg"></div>
        <b-input v-model="query" placeholder="Buscar tu producto"></b-input>
      </b-message>
    <div v-if="$route.path.split('/')[2] != 'menu-digital'">
     <Cart :cart="cartList" :statusShow="showCartRigth" v-click-outside="closeNotification2" @closeNotification="closeNotification"></Cart>
    </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Cart from '@/components/Cart';
import MiOrder from '@/components/modeCarta/MiOrder';
import deliveryAddress from '@/components/templateFood/modals/deliveryAddress2';
import LocalStorage from 'store';
import { v4 as uuidv4 } from 'uuid';
export default {
  components: { Cart, deliveryAddress },
  data () {
    return {
      orderInfo: {},
      orderData: {},
      orderId: null,
      search: false,
      searchMobile: false,
      query: '',
      find: '',
      showCartRigth: false,
      cartList: [],
      cartItem: 0,
      config: {
        toolbarColor: '#ffffff',
        footerColor: '#151E26',
        footerTextColor: '#ffffff',
        buttonColor: '#559A70',
        buttonTextColor: '#ffffff'
      },
      deliveryAddress: null
    };
  },
  computed: {
    ...mapGetters([
      'getCart',
      'getVendor',
      'getProductInfo',
      'loader'
    ])
  },
  mounted () {
    if (this.$route.path.split('/')[2] === 'orderQuery') {
      const data = this.$route.params.id;
      this.getOrderStatus(data);
    }
    const uuid = LocalStorage.get('domicompro-uid-user');
    if (uuid === undefined) {
      this.uuid = uuidv4();
      LocalStorage.set('domicompro-uid-user', this.uuid);
    } else {
      this.getCartAll(uuid);
    }
    const lang = LocalStorage.get('domicompro-lang-user');
    if (lang === undefined) {
      LocalStorage.set('domicompro-lang-user', this.lang);
      // this.$i18n.locale = 'es'
      this.$validator.locale = 'es';
    } else {
      // this.$i18n.locale = lang
      this.lang = lang;
      this.$validator.locale = lang;
    }
    this.addressConfirm();
  },
  methods: {
    goRestaurant () {
      window.location.href = '/' + this.orderInfo.id + '/restaurant';
    },
    getOrderStatus (order) {
      this.loading = true;
      const data = {};
      data.id = order;
      this.$http
        .post('marketplace/client/order/status', data)
        .then(({ data }) => {
          this.orderInfo = data.data.vendor;
          this.orderData = data.data.order;
          this.activeStep = data.data.stateId;
          this.loading = false;
          this.invalid = false;
        })
        .catch((err) => {
          this.loading = false;
          this.invalid = true;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$parent.listErrors(
              err.request.response,
              err.request.status
            ),
            position: 'is-top',
            type: 'is-danger'
          });
        });
    },
    home () {
      if (Object.keys(this.getCart).length > 0) {
        this.$buefy.dialog.confirm({
          message: '<center><i class="fa fa-shopping-cart fa-3x"></i><br><br><strong>¿Estás seguro de que quieres salir?</strong><br>Al hacerlo, se eliminara tu pedido.</center>',
          cancelText: 'No',
          confirmText: 'Si, salir',
          type: 'is-success',
          onConfirm: () => {
            this.getCartDeleteAll();
            if (this.getVendor.offMarketplace === 1) {
              window.location.href = '/' + this.getVendor._id + '/restaurant';
            } else {
              window.location.href = '/';
            }
          }
        });
      } else {
        if (this.getVendor.offMarketplace === 1) {
          if (this.getVendor.website !== undefined) {
            window.location.href = this.getVendor.website;
          } else {
            window.location.href = '/' + this.getVendor._id + '/restaurant';
          }
        } else {
          window.location.href = '/';
        }
      }
    },
    returnGo () {
      if (this.$route.path.split('/')[2] === 'restaurant') {
        this.home();
      }
      if (this.$route.path.split('/')[2] === 'order') {
        window.location.href = '/' + this.$route.path.split('/')[1] + '/restaurant';
      }
    },
    queryOrder () {
      if (this.search === false) {
        this.search = true;
      } else {
        this.search = false;
      }
    },
    searchMobileF () {
      if (this.searchMobile === false) {
        this.searchMobile = true;
      } else {
        this.searchMobile = false;
      }
    },
    // carrito
    getInfoCart () {
      let cartItem = 0;
      if (this.getCart != null) {
        if (Object.keys(this.getCart).length > 0) {
          cartItem += this.getCart.products.length;
        }
        this.cartItem = cartItem;
      } else {
        this.cartItem = cartItem;
      }
    },
    closeNotification () {
      this.showCartRigth = false;
    },
    closeNotification2 (event) {
      if (event.target.id !== 'cartShow') {
        this.showCartRigth = false;
      }
    },
    showNotification () {
      if (this.getVendor.modeMenu === 1) {
        this.$buefy.modal.open({
          parent: this,
          component: MiOrder,
          props: {
            statusShow: true
          },
          hasModalCard: true,
          fullScreen: true,
          canCancel: false,
          scroll: 'keep'
        });
      } else {
        this.showCartRigth = true;
      }
    },
    getCartDeleteAll () {
      this.$http.delete('marketplace/client/cart/delete/all/' + LocalStorage.get('domicompro-uid-user')).then(({ data }) => {
        this.$store.commit('setCart', data.data);
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    getCartAll (id) {
      this.$http.get('marketplace/client/cart/all/' + id).then(({ data }) => {
        if (data.data != null) {
          this.$store.commit('setCart', data.data);
        }
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    listErrors (errors, status) {
      if (status === 403) {
        const data = Object.values(JSON.parse(errors).message);
        let response = '<ul>';
        for (let index = 0; index < data.length; index++) {
          response += '<li>' + data[index] + '</li>';
        }
        response += '</ul>';
        return response;
      } else if (status === 401) {
        const msg = JSON.parse(errors).message;
        const response = '<ul>' + msg + '</ul>';
        return response;
      }
    },
    addressConfirm () {
      if (LocalStorage.get('address_Coverage_client') && LocalStorage.get('center_Coverage_client')) {
        this.deliveryAddress = LocalStorage.get('address_Coverage_client');
        return true;
      } else {
        this.deliveryAddress = 'Recoger en Restaurante';
        return false;
      }
    },
    openPopAddress () {
      var popup = document.getElementById('myPopup');
      var tri = document.getElementById('tri');
      popup.classList.toggle('show');
      tri.classList.toggle('show2');
    },
    changeDeliveryAddress () {
      var tri = document.getElementById('tri');
      this.deliveryAddress = 'Recoger en Restaurante';
      tri.classList.toggle('show2');
    }
  },
  watch: {
    getCart () {
      this.getInfoCart();
      if (this.getCart != null) {
        this.cartList = this.getCart;
      } else {
        this.cartList = [];
      }
    },
    query () {
      this.$emit('query', this.query);
    },
    find () {
      this.$emit('find', this.find);
    }
  }
};
</script>
<style lang="scss">
.button-store-back-2 {
  display: flex;
  align-items: center;
  margin-left: 50px;
  .button-store-back-design-2 {
    cursor: pointer;
    padding-top: 5px;
    height: 37px;
    width: 150px;
    border-radius: 25px;
    background-color: #2d494d;
    box-shadow: 0px 3px 6px #00000080;
    color: white;
    font-family: segoeUiBold;
    font-size: 14px;
    border: none;
    outline: none;
  }
}
.show-cellphone {
  display: none;
}
@media (max-width: 1178px) and (min-width: 947px) {
  .show-cellphone {
    display: none !important;
  }
}
@media (max-width: 946px) and (min-width: 771px) {
  .show-cellphone {
    display: none !important;
  }
}
@media (max-width: 770px) {
  .show-cellphone {
    display: flex !important;
  }
  .hide-cellphone {
    display: none;
  }
}
</style>
