<template>
    <div id="footer" v-if="$route.path.split('/')[2] != 'menu-digital'">
      <!-- <p class="copy">Copyright © {{ new Date().getFullYear() }} Precompro.com - Todos los derechos reservados.</p> -->
      <!-- <div class="terms-logo">
        <img style="height: 25px;" src="@/assets/image/precompro_logo_banner.png" alt=""> <span class="text white size-14">Términos y condiciones</span>
      </div> -->
      <div class="built"><span class="text bold white size-12" style="margin-right: 12px;letter-spacing: 4px;">BUILT BY</span><span class="text bold white">|</span><img style="margin: 0px 0 -7px 15px;" src="@/assets/image/precFooter.png"></div>
    </div>
</template>
<script>
export default {

};
</script>
<style>

</style>
