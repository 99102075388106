<template>
    <div id="outCoverage">
        <span class="text size-20 bold">Fuera de cobertura</span>
        <span class="text size-18 mainText" style="max-width:500px;text-align-last: center;">En este momento no tenemos cobertura en tu zona, puedes elegir otra dirección.</span>
        <div class="btn-new-address" @click="$emit('option', 1)">
            <!-- <i class="fas fa-search" style="width: 22px;height: 22px; margin-right:15px"></i> -->
            <span class="text semi-bold size-18">Buscar otra ubicación</span>
        </div>
    </div>
</template>
<script>
export default {

};
</script>
<style lang="scss">
#outCoverage {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: fade 1s ease-in-out;
    .mainText{
        margin: 5px 0;
        margin-bottom: 30px;
        padding: 0px 5px;
    }
    .btn-new-address{
        width: 90%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--footerBackground);
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 13px;
        cursor: pointer;
        .text{
            color: var(--footerTextColor);
        }
    }
}
</style>
