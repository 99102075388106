<template>
  <section id="mapIntro2">
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>

    <div class="cont-notShowMap" v-if="!showMap">

        <b-select v-model="city" @input="centerCity ()" placeholder="Ciudad" class="w-100-calc-100  selector" id="selectCityList" expanded>
          <option value="Bogotá">Bogotá</option>
          <option value="Medellín">Medellín</option>
        </b-select>
      <div class="flex-center div-mb-20" v-show="false">
        <gmap-map
          :center="center"
          ref="gmap"
          :zoom="zoom"
          style="width: 100%; height: 400px; max-height: 450px;">
        </gmap-map>
      </div>
        <b-input type="text" name="address" v-model="address" class="w-100-calc-100  selector" placeholder="Dirección"></b-input>
        <div @click="validateAddress()" class="w-100-calc-100 btnSearch" expanded :class="{'disabled': address.length < 8}" icon-left="search">
          <span class="text semi-bold size-15">Buscar</span>
        </div>
    </div>
    <div class="cont-notShowMap" v-else>
      <div>
        <span class="text bold size-16">Tu dirección  </span>
        <span class="text size-16">{{ address }}</span>
      </div>
        <span class="text size-17 margin-bot-10">Verifica que el pin coincida con tu dirección</span>
      <div class="map">
        <gmap-map
          :center="center"
          ref="gmap"
          :zoom="zoom"
          :options="options"
          style="width: 100%; height: 200px;">
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :clickable="true"
            :draggable="true"
            @dragend="updateCoordinates"
            :position="m.position"></GmapMarker>
        </gmap-map>
      </div>
        <div @click="next" class="btnSearch"><span class="text semi-bold size-18">Continuar</span></div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import LocalStorage from 'store';
import { gmapApi } from 'vue2-google-maps';
export default {
  props: ['vendorId'],
  name: 'GoogleMap',
  data () {
    return {
      center: { lat: 0, lng: 0 },
      address: '',
      markers: [],
      zoom: 18,
      city: null,
      showMap: false,
      loading: false,
      options: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      }
    };
  },
  mounted () {
    this.getGeolocalization();
  },
  computed: {
    google: gmapApi,
    ...mapGetters([
      'getVendor',
      'globalLoader'
    ])
  },
  methods: {
    updateCoordinates (location) {
      const instance = this;
      const marker = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
      this.center = marker;
      this.markers = [];
      this.markers.push({ position: marker });
      this.$refs.gmap.$mapPromise.then(() => {
        const geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({ location: { lat: location.latLng.lat(), lng: location.latLng.lng() } }, (results, status) => {
          if (status === this.google.maps.GeocoderStatus.OK) {
            const add1 = results[0].address_components[1].long_name;
            const add2 = results[0].address_components[0].long_name;
            const cit = results[0].address_components[4].long_name;
            instance.address = add1 + ' ' + add2;
            instance.city = cit;
          }
        });
      });
    },
    getGeolocalization () {
      const instance = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const marker = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          instance.center = marker;
          instance.$refs.gmap.$mapPromise.then(() => {
            var latlng = new this.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            const geocoder = new this.google.maps.Geocoder();
            geocoder.geocode({ latLng: latlng }, (results, status) => {
              if (status === this.google.maps.GeocoderStatus.OK) {
                Object.values(results).map(item => {
                  if (item.geometry.location_type === 'RANGE_INTERPOLATED') {
                    instance.address = item.formatted_address;
                    instance.city = item.address_components[4].long_name;
                  }
                });
              }
            });
          });
        });
      }
    },
    setAddress () {
      const infoAddress = this.address + ',' + this.city;
      this.geolocate(infoAddress);
    },
    next () {
      this.loading = true;
      this.center.vendorId = this.getVendor._id;
      this.$http.post('marketplace/client/coverage/validate', this.center).then(({ data }) => {
        this.loading = false;
        this.isCoverageValid = data.coverageStatus;
        if (!this.isCoverageValid) {
          this.$emit('option', 3);
        } else {
          LocalStorage.remove('tienda_Coverage_client');
          LocalStorage.set('address_Coverage_client', this.address);
          LocalStorage.set('center_Coverage_client', this.center);
          this.$emit('changeAddress', this.address);
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    geolocate (address) {
      const instance = this;
      this.$refs.gmap.$mapPromise.then(() => {
        const geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === this.google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();
            const marker = {
              lat: latitude,
              lng: longitude
            };
            instance.center = marker;
            instance.markers.push({ position: marker });
            instance.showMap = true;
            // instance.coverage = google.maps.geometry.poly.containsLocation(results[0].geometry.location, instance.$refs.polygon.$polygonObject)
          }
        });
      });
    },
    validateAddress () {
      if (this.address.length > 8) {
        this.setAddress();
      }
    },
    centerCity () {
      if (this.city === 'Medellín') {
        this.center.lat = 6.230833;
        this.center.lng = -75.590553;
      } else if (this.city === 'Bogotá') {
        this.center.lat = 4.624335;
        this.center.lng = -74.063644;
      }
    }
  }
};
</script>
<style lang="scss">
  #mapIntro2 {
    width: 100%;
    // height: 100%;
    animation: fade 1s ease-in-out;
    .w-100-calc-100 {
      width: calc(100% - 100px);
    }
    .btnSearch {
      height: 35px;
      min-height: 35px;
      width: 90%;
      background: var(--footerBackground);
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 10px;
      .text{
          color: var(--footerTextColor);
      }
      &.disabled{
        background: #E6E6E6;
        cursor: not-allowed;
        .text{
          color: #9B9B9B;
        }
      }
      &.width-80{
        width: 80%;
        margin-bottom: 20px;
      }
    }
    .h-50-px {
      span {
        height: 50px;
      }
      input {
        height: 50px;
      }
    }
    #selectCityList {
      height: 40px;
    }
    .mapIntroBtnClose {
      color: #000000;
      justify-content: flex-end!important;
      align-items: flex-end!important;
    }
    .mapIntro-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 128px;
      margin: 10px 0px 10px 10px;
    }
    .flex-center {
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .flex-end {
      justify-content: flex-end;
      align-items: flex-end;
      display: flex;
      right: 15px;
      float: right;
      position: relative;
      top: 15px;
    }
    .size-24 {
      font-size: 24px;
    }
    .size-18 {
      font-size: 18px;
    }
    .div-mb-20 {
      margin-bottom: 20px;
    }
    .div-m-30 {
      margin: 50px 0px 30px 0px;
    }
    .div-mt-20 {
      margin-top: 20px;
    }
    .ml-10 {
      margin-left: 10px;
    }
    .h-50-px {
      height: 50px;
    }
    .h-100 {
      height: 100%;
    }
    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: #000000;
    }
    .cont-btn-options{
        display: flex;
        margin:10px 0 10px 0;
        width: 90%;
        animation: fade 1s ease-in-out;
        .btn-option{
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #E6E6E6;
            flex: 1;
            cursor: pointer;
            &.border-left{
                border-radius: 7px 0px 0px 7px;
            }
            &.border-right{
                border-radius: 0px 7px 7px 0px;
            }
            &.active{
                box-shadow: 0px 3px 6px #00000029;
                background: var(--footerBackground);
                .text{
                    color: var(--footerTextColor);
                }
            }
            &.disabled{
                .text{
                    color: #9B9B9B;
                }
            }
        }
    }
    .selector{
      width: 90%;
      margin: 5px 0;
    }
    .cont-notShowMap{
      width: 100%;
      height: calc(100% - 50px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .map{
      width: 90%;
      height: 250px;
    }
  }
  @media(max-width: 767px) {
    #mapIntro {
      width: 100%;
      .size-24 {
        font-size: 18px;
      }
      .w-100-calc-100 {
        width: 100%;
      }
    }
  }
</style>
