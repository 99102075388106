<template>
  <aside
    :class="{'is-placed-right-active': viewCartList ,'is-placed-right':  viewCartList, 'is-placed-left': !viewCartList, 'close-animated': closeAnimated && !viewCartList}"
    class="aside notSelectUser"
  >
    <div
      :class="{advance: Object.keys(cart).length == 0 || Object.keys(cart.products).length == 0, 'center-div': Object.keys(cart).length == 0 || Object.keys(cart.products).length == 0}"
      class="aside-container ps ps--active-y"
    >
      <b-loading :active.sync="loading" :can-cancel="false" :is-full-page="true">
        <b-icon custom-class="fa-spin" icon="sync-alt" size="is-large"></b-icon>
      </b-loading>
      <div @click="$emit('closeNotification', true),closeAnimated = true" class="btnClose">
        <i class="icon-mks black icon_arrow_left" style="height: 15px;"></i>
      </div>
      <div class="ordenTittle">
        <span class="ordenTittle__text">Mi orden</span>
      </div>
      <div
        :class="{'bar-progre': getTotal(cart.products) < getVendor.minPriceForOrder}"
        class="cart-content"
        v-if="Object.keys(cart).length > 0 && Object.keys(cart.products).length > 0"
      >
        <div
          :key="'cart-product#' + pIndex"
          class="ProductCont"
          v-for="(products, pIndex) in cart.products"
        >
          <div @click="openModalDelete(products, pIndex)" class="trashItem">
            <i class="far fa-trash-alt" style="margin-right: 0px !important;"></i>
          </div>
          <div class="contImgDescrip">
            <div
              :style="{'background-image': 'url('+products.image+')','background-size':'cover'}"
              class="imgProduct"
              v-if="products.image"
            ></div>
            <div class="imgProduct" style="border: 1px solid rgba(170, 170, 170, 0.4);" v-else></div>
            <div class="contDescript">
              <span
                class="text bold size-14 ellipsis"
                style="padding-right: 23px; padding-top: 5px;"
              >
                {{products.name}}
                <img
                  :style="{right: (products.specs) ? '35px' : '10px'}"
                  class="promoRight"
                  height="10"
                  src="/2x1.png"
                  v-show="isDeals(products.deal, '2x1')"
                  width="20"
                />
                <img
                  :style="{right: (products.specs) ? '35px' : '10px'}"
                  class="promoRight"
                  height="10"
                  src="/3x2.png"
                  v-show="isDeals(products.deal, '3x2')"
                  width="30"
                />
              </span>
              <div :key="n" class="aditions" v-for="(adition,n) in products.options.products">
                <p class="text bold size-12">{{adition.name}}</p>
                <div :key="i" class="item" v-for="(item, i) in adition.items">
                  <div class="contAdition">
                    <span class="text size-10">{{ item.item }}</span>
                    <span
                      class="text size-10"
                    >{{item.price | currency('$ ', 0, { thousandsSeparator: ',' })}}</span>
                  </div>
                </div>
              </div>
              <div class="contPrice">
                <div class="cont-btn-items">
                  <span
                    @click="deleteProductCart(products._id, pIndex)"
                    id="minus"
                    v-show="products.quantity == 1"
                  >
                    <i class="fa fa-minus"></i>
                  </span>
                  <span
                    @click="updateCart(products, 'min', pIndex)"
                    id="minus"
                    v-show="products.quantity >= 2"
                  >
                    <i class="fa fa-minus"></i>
                  </span>
                  <span id="quantity">{{ products.quantity }}</span>
                  <span @click="updateCart(products, 'max', pIndex)" id="plus">
                    <i class="fa fa-plus"></i>
                  </span>
                </div>
                <div>
                  <div class="cartPrice">
                    <span
                      :class="{'textLine': (products.percentOff || products.categoryPercentOff)}"
                      class="text segoeBold bold size-16"
                      style="padding-top: 6px;"
                    >{{ products.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                    <span
                      class="cartPrice-discountPercent"
                      v-if="(products.percentOff || products.categoryPercentOff)"
                    >-{{(products.percentOff)? products.categoryPercentOff + products.percentOff : products.categoryPercentOff }}%</span>
                  </div>
                </div>
                <span
                  class="cartPrice"
                  v-if="(products.percentOff || products.categoryPercentOff)"
                >{{ (products.categoryPercentOff)?(getTotalProduct(products) - (products.price * (products.categoryPercentOff/100))):getTotalProduct(product) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
              </div>
            </div>
          </div>
          <div class="observation" v-if="products.options.observation">
            <span class="text bold size-12">Comentario adicional</span>
            <span class="text size-12">{{products.options.observation}}</span>
          </div>
        </div>
      </div>
      <div class="p-10 hight-100" v-else>
        <div class="flex-center" style="padding-top: 35px;">
          <img src="@/assets/image/empty-cart.svg" />
          <span class="text bold size-18" style="padding-top: 30px;">Aún no tienes productos en el carrito</span>
          <span
            class="text segoe size-12"
            style="max-width:200px"
          >Selecciona la comida que mas te gusta y recíbela en tu casa</span>
          <div @click="$emit('closeNotification', true),closeAnimated = true" class="btn-G" style="position: initial; margin-top: 30px;">
            <span class="text bold size-16" style="color: var(--buttonTextColor);">Ir a la tienda</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="p-10 infoCart"
      v-if="Object.keys(cart).length > 0 && Object.keys(cart.products).length > 0"
    >
      <div class="infoCart-item">
        <span class="text size-15">Subtotal</span>
        <span
          class="text segoe size-12"
        >{{ getSubTotal(cart.products) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
      </div>
      <div class="infoCart-item" v-if="getVendor.tipPercent !== undefined">
        <div style="display: flex;align-items: center;">
          <input style="margin-right: 5px;" type="checkbox" v-model="tip" />
          <span :class="{'disabled': !tip}" class="text bold size-15">Propina</span>
        </div>
        <div style="display: flex;align-items: center;">
          <div @click="tipMount = true" class="animation-fade" v-show="tip  && !tipMount">
            <i class="far fa-edit"></i>
          </div>
          <div @click="tipMount = false, tipTotal = 0" class="animation-fade" v-show="tipMount">
            <i class="fas fa-times"></i>
          </div>
          <span
            :class="{'disabled': !tip}"
            class="text segoe size-16"
            v-if="!tipMount"
          >{{ this.tipValue | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
          <input class="inputTip" id name type="number" v-if="tipMount" v-model="tipTotal" />
        </div>
      </div>
      <div class="infoCart-item">
        <span class="textsize-16">Descuento</span>
        <small>- {{ totalDiscount | currency('$ ', 0, { thousandsSeparator: ',' }) }}</small>
      </div>
      <div class="infoCart-item">
        <span class="text bold size-18">Total</span>
        <strong>{{ totalPrice | currency('$ ', 0, { thousandsSeparator: ',' }) }}</strong>
      </div>
      <div
        class="infoCart-item column"
        v-if="(Object.keys(cart).length > 0 && Object.keys(cart.products).length > 0) && (getCompareValue() < getVendor.minPriceForOrder)"
      >
        <p class="text bold">Mínimo para pedido</p>
        <b-progress
          :max="parseInt(getVendor.minPriceForOrder)"
          :value="(tempTotalPrice != null ) ? tempTotalPrice :totalPrice"
          show-value
        >{{(tempTotalPrice != null ) ? tempTotalPrice :totalPrice | currency('$ ', 0, { thousandsSeparator: ',' }) }} / {{getVendor.minPriceForOrder | currency('$ ', 0, { thousandsSeparator: ',' })}}</b-progress>
      </div>
    </div>
    <div
      class="p-10 clearCart"
      v-if="Object.keys(cart).length > 0 && Object.keys(cart.products).length > 0  && (getCompareValue() > ((getVendor.minPriceForOrder)? getVendor.minPriceForOrder : 0 ))"
    >
      <div
        class="infoCart-item"
        style="flex-direction: column; align-items: flex-start; padding: 0; padding-bottom: 11px;"
      >
        <span class="text size-14">Total </span>
        <strong
          class="text segoeBold"
          style="font-weight: 900; font-size: 21px;"
        >{{ totalPrice | currency('$ ', 0, { thousandsSeparator: ',' }) }}</strong>
      </div>
      <div @click="viewCart()" class="btn-G continue">
        <span class="text bold size-16" style="color: var(--buttonTextColor);">Continuar</span>
      </div>
    </div>
    <b-modal
      :active.sync="modalDelete"
      :can-cancel="true"
      :width="359"
      id="modal-delete"
      v-if="modalDelete === true"
    >
      <div class="delete-modal">
        <div class="icon-borders"></div>
        <i
          class="icon-mks icon_delete_product"
          style="padding-bottom:2rem; height: 34px; width: 34px; background: black;"
        ></i>
        <span
          class="text bold center margin-bot-10"
          style="padding-top: 32px;"
        >¿Estas seguro de eliminar de tu orden el siguiente producto?</span>
        <b style="padding-top: 5px;">{{productDelete.name}}</b>
        <div @click="deleteProductCart(productDelete._id, productIndex)" style="margin-top: 20px;" class="btn-G continue">
          <span class="text size-16" style="color: var(--buttonTextColor);">Confirmar</span>
        </div>
      </div>
    </b-modal>
  </aside>
</template>

<script>
import LocalStorage from 'store';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  props: ['cart', 'statusShow'],
  data () {
    return {
      isOpen: true,
      loading: false,
      viewCartList: false,
      closeAnimated: false,
      tip: true,
      tipMount: false,
      tipTotal: 0,
      totalDiscount: 0,
      totalPrice: 0,
      tempTotalPrice: null,
      modalDelete: false,
      productDelete: null,
      productIndex: null,
      tipValue: 0
    };
  },
  mounted () {
    const thes = this;
    setTimeout(function () {
      if (thes.getVendor.tipPercent === undefined) {
        thes.tip = false;
      }
    }, 1000);
  },
  computed: {
    ...mapGetters([
      'getVendor',
      'getCart'
    ])
  },
  methods: {
    openModalDelete (selectedProduct, productIndex) {
      this.modalDelete = true;
      this.productDelete = selectedProduct;
      this.productIndex = productIndex;
    },
    closeModalDelete () {
      this.modalDelete = false;
      this.productDelete = null;
      this.productIndex = null;
    },
    isDeals (data, type) {
      let show = false;
      Object.values(data).map(item => {
        if (item.type === type) {
          show = true;
        }
      });
      return show;
    },
    shortText (text) {
      if (text) {
        if (text.length > 25) {
          return text.substr(0, 25) + '...';
        } else {
          return text;
        }
      } else {
        return 'N/A';
      }
    },
    async deleteProductCart (id, p) {
      this.loading = true;
      const data = {};
      data.index = p;
      let products = this.cart.products;
      products = products.filter(p => p._id !== id);
      await this.$http.put('marketplace/client/cart/' + LocalStorage.get('domicompro-uid-user') + '/item/' + id, data).then(({ data }) => {
        if (products.length > 0) {
          const newCart = { ...data.data, products: products };
          this.$store.commit('setCart', newCart);
        } else {
          this.$store.commit('setCart', data.data);
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      }).finally(() => {
        this.modalDelete = false;
        this.productDelete = null;
        this.productIndex = null;
      });
    },
    deleteCartAll () {
      this.loading = true;
      this.$http.delete('marketplace/client/cart/delete/all/' + LocalStorage.get('domicompro-uid-user')).then(({ data }) => {
        this.$store.commit('setCart', data.data);
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    updateCart (product, type, index) {
      if (this.validateProductPromo(product) || type === 'min') {
        this.loading = true;
        if (type === 'max') {
          product.quantity += 1;
        }
        if (type === 'min') {
          product.quantity -= 1;
        }
        product.index = index;
        this.$http.put('marketplace/client/cart/update/' + LocalStorage.get('domicompro-uid-user') + '/item/' + product._id, product).then(({ data }) => {
          const newCartProducts = data.data.products.map((data) => {
            if (this.cart.products) {
              for (let index = 0; index < this.cart.products.length; index++) {
                const oldProduct = this.cart.products[index];
                if (data._id === oldProduct._id) {
                  data.categoryPercentOff = oldProduct.categoryPercentOff;
                  return data;
                }
              }
            } else {
              data = product;
              return data;
            }
          });
          const cart = { ...data.data, products: newCartProducts };
          this.$store.commit('setCart', cart);
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 1000,
            message: this.$parent.listErrors(err.request.response, err.request.status),
            position: 'is-top',
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'No se pueden agregar más productos de promoción al carrito',
          position: 'is-top',
          type: 'is-danger'
        });
      }
    },
    validateProductPromo (product) {
      const cartProducts = this.getCart.products;
      let cartLength = 0;
      if (cartProducts !== undefined) {
        cartLength = cartProducts.length;
      }
      const maxPromo2x1 = this.getVendor.maxPromo2x1;
      const maxPromo3x2 = this.getVendor.maxPromo3x2;
      let promo2x1 = 0;
      let promo3x2 = 0;
      if (cartLength === 0 ||
        product.deal.length === 0 ||
        (maxPromo2x1 === undefined && product.deal[0].type === '2x1') ||
        (maxPromo3x2 === undefined && product.deal[0].type === '3x2')
      ) {
        return true;
      } else {
        _.forEach(cartProducts, function (pd) {
          if (Object.keys(pd.deal).length > 0) {
            if (pd.deal[0].type === '2x1') {
              promo2x1 = promo2x1 + pd.quantity;
            }
            if (pd.deal[0].type === '3x2') {
              promo3x2 = promo3x2 + pd.quantity;
            }
          }
        });
        const total2x1 = Math.floor(promo2x1 / 1);
        const total3x2 = Math.floor(promo3x2 / 1);
        if (total2x1 < maxPromo2x1 && product.deal[0].type === '2x1') {
          return true;
        }
        if (total3x2 < maxPromo3x2 && product.deal[0].type === '3x2') {
          return true;
        }
      }
      return false;
    },
    deleteMerchantCart (id) {
      this.loading = true;
      const data = {};
      data.id = id;
      this.$http.put('marketplace/client/cart/' + LocalStorage.get('domicompro-uid-user') + '/merchant/delete', data).then(({ data }) => {
        this.$store.commit('setCart', data.data);
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    async getTotal (products) {
      let value = 0;
      if ((products && Array.isArray(products) && products.length > 0)) {
        const coupon = (LocalStorage.get('couponCodeData') !== undefined)
          ? parseInt(window.atob(LocalStorage.get('couponCodeData')))
          : 0;
        for (let index = 0; index < products.length; index++) {
          const product = products[index];
          let discount = 0;
          if (product.percentOff) {
            if (product.categoryPercentOff) {
              discount = product.percentOff + product.categoryPercentOff;
            } else {
              discount = product.percentOf;
            }
          } else {
            if (product.categoryPercentOff) {
              discount = product.categoryPercentOff;
            } else {
              discount = 0;
            }
          }
          value += product.quantity * (product.price - (product.price * discount / 100));
        }
        if (LocalStorage.get('couponCodeData') !== undefined) {
          if (coupon > value) {
            this.tempTotalPrice = value;
          }
          value = value - parseInt(window.atob(LocalStorage.get('couponCodeData')));
          if (value < 0) {
            value = 0;
          }
        }
        if (this.tip) {
          if (!this.tipMount) {
            this.tipValue = value * (10 / 100);
            value += value * (10 / 100);
          } else {
            if (this.tipTotal > 0) {
              value += parseInt(this.tipTotal);
            }
          }
        } else {
          this.tipValue = value * (10 / 100);
        }
        this.totalPrice = value;
      }
    },
    getSubTotal (products) {
      let value = 0;
      Object.values(products).map((item) => {
        value += (item.quantity * item.price);
      });
      return value;
    },
    getTotalDescuento (products) {
      let value = 0;
      if ((products && Array.isArray(products) && products.length > 0)) {
        for (let index = 0; index < products.length; index++) {
          const product = products[index];
          if (product.percentOff >= 0) {
            const discount = product.percentOff + product.categoryPercentOff;
            value += product.quantity * (product.price * discount / 100);
          }
        }
        if (LocalStorage.get('couponCodeData') !== undefined) {
          value = value + parseInt(window.atob(LocalStorage.get('couponCodeData')));
        }
        this.totalDiscount = value;
      }
    },
    getCompareValue () {
      if (this.tempTotalPrice != null) {
        return this.tempTotalPrice;
      }
      return this.totalPrice;
    },
    viewCart () {
      if (this.getVendor.tipPercent !== undefined) {
        if (this.tip) {
          if (!this.tipMount) {
            this.tipTotal = this.getSubTotal(this.getCart.products) * (10 / 100);
            this.$store.commit('setTipStorage', parseInt(this.tipTotal));
          } else {
            this.$store.commit('setTipStorage', parseInt(this.tipTotal));
          }
        }
      }
      this.$emit('closeNotification', false);
      this.$router.push('/' + this.getVendor._id + '/order');
    },
    getTotalProduct (product) {
      let priceComplete = product.price;
      if (product.percentOff) {
        priceComplete = product.price - (product.price * product.percentOff / 100);
      }
      let total = priceComplete * product.quantity;
      if (product.options.extra > 0) {
        total += product.options.extra;
      }
      return total;
    },
    updateCartItem (product, pIndex) {
      const data = {};
      product.edit = true;
      data.product = product;
      data.index = pIndex;
      this.$store.commit('setProductInfo', data);
    }
  },
  watch: {
    statusShow () {
      this.viewCartList = this.statusShow;
    },
    tip () {
      if (!this.tip) {
        this.$store.commit('setTipStorage', 0);
        this.tipMount = false;
        this.tipTotal = 0;
      }
    },
    cart () {
      if (this.cart.products) {
        let borrar = false;
        for (let i = 0; i < this.cart.products.length; i++) {
          if (this.cart.products[i].vendorId !== this.$route.params.id) {
            borrar = true;
          }
        }
        if (borrar && this.$route.path !== '/') {
          this.deleteCartAll();
        }
        this.getTotalDescuento(this.cart.products);
        this.getTotal(this.cart.products);
      };
    }
  }
};
</script>

<style scoped lang="scss">
#modal-delete {
  .modal.modal-content {
    width: 359px !important;
  }
}
.delete-modal {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  padding-top: 2.6rem;
  height: 286px;
  align-items: center;
  padding-bottom: 20px;
  background: white !important;
  color: black;
  border-radius: 10px;
  // gap: 1.5rem;
  // padding: 1rem;
  min-width: 359px;
  position: relative;
  .icon-borders {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    border: 2px solid black;
    position: absolute;
    top: 28px;
  }
}
.promoRight {
  float: right;
  right: 10px;
  position: absolute;
}
// svg {
//   margin-right: 5px !important;
// }
.cart-header {
  padding: 20px;
  padding-right: 40px;
  position: relative;
  z-index: 2147483647;
  background-color: inherit;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;
  &-title {
    font-size: 18px;
    color: #000;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-btn-close {
    position: absolute;
    right: 9px;
    top: 8px;
    font-size: 22px;
    color: #000;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      box-shadow: none;
      -webkit-box-shadow: none;
      border-color: transparent;
      transform: translateY(-1px);
    }
    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
      border-color: transparent;
    }
    &:focus:not(:active) {
      box-shadow: none;
      -webkit-box-shadow: none;
      border-color: transparent;
    }
    &:active {
      box-shadow: none;
      -webkit-box-shadow: none;
      border-color: transparent;
    }
  }
}
.cart-content {
  padding: 20px 5px 5px 5px;
  height: calc(100% - 55px);
  overflow: auto;
  position: relative;
  &.bar-progre {
    height: calc(100% - 125px);
  }
  &::-webkit-scrollbar-track {
    background: #e3e3e3 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--buttonBackground) !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    border: none !important;
  }
}
.product-title {
  font-size: 14px;
}
.product-description {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  -webkit-line-clamp: 2;
  font-size: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.bor {
  margin-top: 10px;
  border-top: 1px solid #dcdcdc;
  padding-top: 5px;
}

aside.aside {
  width: 5rem;
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 40;
  height: calc(100% - 60px);
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #ffffff; /*#051d38;*/
  //background-image: -webkit-gradient(linear,left top,right top,from(#051d38),to(#1e344c));
  //background-image: linear-gradient(90deg,#051d38,#1e344c);
}

aside.aside.is-placed-right {
  -webkit-transition: right 0.25s ease-in-out 50ms;
  transition: right 0.25s ease-in-out 50ms;
  width: 100%;
  max-width: 435px;
  display: flex;
  flex-direction: column;
  left: auto;
  border-left: 1px solid #f8f8f8;
  right: -20rem;
  box-shadow: -1px 32px 20px #0000003b;
  color: #151e26;
  animation: rightToLeft 1.5s;
}

aside.aside.is-placed-left {
  -webkit-transition: left 0.25s ease-in-out 50ms;
  transition: left 0.25s ease-in-out 50ms;
  width: 20rem;
  display: block;
  border-left: 1px solid #f8f8f8;
  left: inherit;
  right: -320px !important;
  color: #151e26;
}

.close-animated {
  animation: leftToRight 2s;
}

#cartEmpty {
  color: #151e26 !important;
}

#productName {
  color: #000000 !important;
}

aside.aside.is-placed-right.is-placed-right-active {
  right: 0px;
}

aside.aside .aside-container {
  position: relative;
  height: 100%;
}

.ps {
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.is-black {
  color: rgba(0, 0, 0, 0.7607843137254902) !important;
}

.is-white-flex {
  display: flex;
}

.p-top-5 {
  padding-top: 5px;
}

.p-1 {
  padding: 1px;
}

.center-div {
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  height: 100%;
}

.advance {
  display: flex !important;
}

.p-10 {
  padding: 10px;
  text-align: center;
  &.hight-100 {
    height: 100%;
  }
}

.clearCart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px 12px 0px 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.minimunOrder {
  position: absolute;
  bottom: 166px;
  max-width: 20rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  width: 19.9rem;
  flex-direction: column;
  .progress-wrapper {
    width: 100%;
  }
}
.infoCart {
  width: 100%;
  align-items: center;
  font-family: "OpenSans";
  padding-left: 20px;
  padding-right: 20px;
  &-item {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid rgba(170, 170, 170, 0.4);
    margin: 8px 0;
    padding: 8px 0;
    &.column {
      flex-direction: column;
    }
    &:first-child {
      // border-top: 1px solid rgba(170, 170, 170, 0.4);
    }
  }
}

.itemCart {
  display: flex;
  width: 50%;
  justify-content: center;
  align-content: center;
  border-color: #151e26;
  &.directionCol {
    flex-direction: column;
  }
}
.flex-col {
  flex-direction: column;
}
#plus {
  background-color: var(--buttonBackground);
  border-radius: 0 10px 10px 0;
  height: 30px;
  width: 30px;
  color: var(--buttonTextColor);
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  .svg-inline--fa {
    height: 10px;
    width: 10px;
  }
}

#minus {
  background-color: var(--buttonBackground);
  border-radius: 10px 0 0 10px;
  height: 30px;
  width: 30px;
  color: var(--buttonTextColor);
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  .svg-inline--fa {
    height: 10px;
    width: 10px;
  }
}

#quantity {
  width: 100%;
  color: var(--buttonTextColor);
  background-color: var(--buttonBackground);
  justify-content: center;
  align-content: center;
  display: flex;
  user-select: none;
  padding: 2.5px;
  height: 30px;
  width: 30px;
  pointer-events: none;
  font-weight: bold;
  // border: 1px solid #7070702e;
}

.cartPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "bold";
  font-size: 13px;
  padding-right: 48px;
  &-discountPercent {
    font-size: 11px;
    color: #fff;
    padding: 1px 3px;
    text-align: center;
    border-radius: 8px;
    font-size: 10px;
    background-color: #ff2425;
    margin-left: 3px;
  }
}
.text {
  &.textLine {
    color: #9faab7;
    text-decoration: line-through;
    font-size: 12px;
  }
}
.text {
  &.disabled {
    color: grey;
  }
}
.is-64x64 {
  height: 82px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image img {
  max-height: 80px;
}

.btn-right {
  float: right;
  right: 5px;
  position: absolute;
  &:hover {
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
}

.carousel-footer {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
}

.m-left-5 {
  margin-left: 5px;
}

.m-top-5 {
  margin-top: 5px;
}

.imagenVendor {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 #e5edef;
  background-position: center;
  background-size: cover;
}

.nameVendor {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}

.div-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 10px;
  color: #000000;
}

.article {
  padding: 0.3rem !important;
}

.btn-vaciar {
  background-color: rgb(162, 42, 22) !important;
  border-radius: 0px !important;
  &:hover {
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
  &.padding-lateral {
    padding: 0 20px;
  }
  &.green {
    background-color: #559a70 !important ;
  }
}

.card .media:not(:last-child) {
  margin-bottom: 0.5rem !important;
}
.media-content .content {
  max-width: calc(20rem - 128px);
}
.media + .media {
  margin-top: 0.5rem !important;
  padding-top: 0.5rem !important;
}
.inputTip {
  width: 80px;
  border: inherit;
  border-bottom: 1px solid;
  animation: fade 0.5s ease-in-out;
}
//new style
.btnClose {
  display: flex;
  font-size: 16px;
  position: absolute;
  left: 10px;
  top: 3px;
  cursor: pointer;
  z-index: 1;
  padding-top: 30px;
}
.flex {
  &-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
.btn-G {
  height: 43px;
  width: 100%;
  max-width: 200px;
  background: var(--buttonBackground);
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  bottom: 60px;
  &.continue {
    margin-top: 0;
    position: inherit;
    bottom: 0;
  }
  span {
    font-family: "OpenSansBold";
  }
}
.contImgDescrip {
  display: flex;
  width: 100%;
  gap: 0 8px;
  .imgProduct {
    height: 58px;
    min-height: 58px;
    width: 61px;
    min-width: 61px;
    margin: 6px;
    border-radius: 8px;
  }
  .contDescript {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
  }
}
.contPrice {
  display: flex;
  justify-content: space-between;
  // gap: 4.6rem;
  padding-top: 5px;
}
.ProductCont {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgba(170, 170, 170, 0.4);
  border-radius: 10px;
  margin-bottom: 4px;
  font-family: "OpenSansSemiBold";
}
.cont-btn-items {
  display: flex;
}
.contAdition {
  display: flex;
  justify-content: space-between;
}
.observation {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 90px;
  margin-bottom: 35px;
  padding-left: 8px;
}
.ordenTittle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  &__text {
    font-family: "OpenSans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    text-align: center;
  }
}
.trashItem {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 28px;
  height: 28px;
  background-color: var(--buttonBackground);
  color: var(--buttonTextColor);
  border-end-end-radius: 10px;
  border-top-left-radius: 10px;
}
</style>
