<template>
<div id="actualAddress">
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
    <div class="addressPoint" @click="setValidatePoint">
        <div class="cont-address">
            <i class="fas fa-map-marker-alt" style="width: 20px;height: 27px; margin-right:15px"></i>
            <div class="address">
                <span class="text semi-bold size-20">Enviar a mi ubicación actual</span>
                <span class="text size-18">{{ address }}</span>
            </div>
        </div>
        <div class="rightIconContainer"><i class="fas fa-chevron-right"></i> </div>

    </div>
    <div class="flex-center div-mb-20" v-show="false">
      <gmap-map
        :center="center"
        ref="gmap"
        :zoom="zoom"
        style="width: 100%; height: 400px; max-height: 450px;">
      </gmap-map>
    </div>

    <!-- <span class="text size-19 center session">Si ya tienes una dirección guardada inicia sesión y seleccionala</span>
    <span class="text family-bold size-20 margin-bot-10" @click="showLogin()" style="cursor:pointer">Iniciar sesión</span> -->
    <div class="btn-new-address" @click="$emit('option', 2)">
        <i class="fas fa-search" style="width: 16px;height: 16px; margin-right:15px"></i>
        <span class="text semi-bold size-25">Elegir una dirección diferente</span>
    </div>
</div>
</template>
<script>
import LocalStorage from 'store';
import { gmapApi } from 'vue2-google-maps';
// import Login from '@/components/auth/login'
export default {
  props: ['vendorId'],
  data () {
    return {
      center: { lat: 0, lng: 0 },
      zoom: 18,
      address: '',
      loading: false,
      isCoverageValid: null
    };
  },
  mounted () {
    this.getGeolocalizationCurrent();
  },
  computed: {
    google: gmapApi
  },
  methods: {
    getGeolocalizationCurrent () {
      const instance = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const marker = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          instance.center = marker;
          instance.$refs.gmap.$mapPromise.then(() => {
            var latlng = new this.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            const geocoder = new this.google.maps.Geocoder();
            geocoder.geocode({ latLng: latlng }, (results, status) => {
              if (status === this.google.maps.GeocoderStatus.OK) {
                Object.values(results).map(item => {
                  if (item.geometry.location_type === 'RANGE_INTERPOLATED') {
                    instance.address = item.formatted_address;
                  }
                });
              }
            });
          });
        });
      }
    },
    // showLogin () {
    //   this.$buefy.modal.open({
    //     parent: this,
    //     component: Login,
    //     hasModalCard: true,
    //     trapFocus: true,
    //     canCancel: false
    //   })
    // },
    setValidatePoint () {
      this.loading = true;
      this.center.vendorId = this.vendorId;
      if (this.vendorId) {
        this.$http.post('marketplace/client/coverage/validate', this.center).then(({ data }) => {
          this.loading = false;
          this.isCoverageValid = data.coverageStatus;
          if (!this.isCoverageValid) {
            this.$emit('option', 3);
          } else {
            LocalStorage.remove('tienda_Coverage_client');
            LocalStorage.set('address_Coverage_client', this.address);
            LocalStorage.set('center_Coverage_client', this.center);
            // this.$emit('dataCoverage',data.vendors)
            this.$emit('changeAddress', this.address);
          }
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.$http.post('marketplace/client/coverage/validate', this.center).then(({ data }) => {
          this.loading = false;
          this.isCoverageValid = data.coverageStatus;
          if (!this.isCoverageValid) {
            this.$emit('option', 3);
          } else {
            LocalStorage.remove('tienda_Coverage_client');
            LocalStorage.set('address_Coverage_client', this.address);
            LocalStorage.set('center_Coverage_client', this.center);
            this.$emit('dataCoverage', data.vendors);
          }
        }).catch(() => {
          this.loading = false;
        });
      }
    }
  }
};
</script>
<style lang="scss">
#actualAddress{
    // height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: fade 1s ease-in-out;
    gap: 30px;
    .fa-map-marker-alt{
      object-fit:fill;
    }
    .addressPoint{
        display: flex;
        width: 90%;
        height: 66px;
        border: 1px solid #324045;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding-left: 13px;
        background-color: white;
        .rightIconContainer{
          background-color: #324045;
          height: 100%;
          width: 33px;
          display: flex;
          align-items: center ;
          justify-content: center;
          border-radius: 6px;
        }
        .fa-chevron-right{
          height: 32%;
          width: 32%;
          path{
          fill: white;
          }

        }
        .cont-address{
            display: flex;
            align-items: center;
            .fa-map-marker-alt{
              path{
                fill: #324045;
              }
            }
            .address span.text{
                display: flex;
                flex-direction: column;
                color: #324045;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
            }
        }
    }
    .btn-new-address{
        width: 330px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 6px;
        border: solid 1px #324045;
        cursor: pointer;
        svg{
          path{
          fill:#324045 ;
          }

        }
        .text{
            color: #324045;
        }
    }
    .text{
        &.session{
            width: 370px;
            margin: 30px 0 10px 0;
        }
    }
    .close{
        position: absolute;
        right: 25px;
        top: 15px;
    }
}
</style>
