<template>
<div id="actualAddress2">
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
    <div class="addressPoint" @click="setValidatePoint">
        <div class="cont-address">
            <div class="pointerA">
              <i class="icon-mks icon_pointerMap"></i>
            </div>
            <div class="address">
                <span class="text semi-bold size-14" style="text-align-last: left;">Enviar a mi ubicación actual</span>
                <span class="text size-14">{{ address }}</span>
            </div>
        </div>
        <i class="fas fa-chevron-right" style="width: 22px;height: 22px;"> fafawfa</i>
    </div>
    <div class="flex-center div-mb-20" v-show="false">
      <gmap-map
        :center="center"
        ref="gmap"
        :zoom="zoom"
        style="width: 100%; height: 400px; max-height: 450px;">
      </gmap-map>
    </div>

    <!-- <span class="text size-19 center session">Si ya tienes una dirección guardada inicia sesión y seleccionala</span>
    <span class="text family-bold size-20 margin-bot-10" @click="showLogin()" style="cursor:pointer">Iniciar sesión</span> -->
    <div class="btn-new-address" @click="$emit('option', 2)">
        <!-- <i class="fas fa-search" style="width: 22px;height: 22px; margin-right:15px"></i> -->
        <span class="text semi-bold size-18">Elegir una dirección diferente</span>
    </div>
</div>
</template>
<script>
import LocalStorage from 'store';
import { gmapApi } from 'vue2-google-maps';
// import Login from '@/components/auth/login'
export default {
  props: ['vendorId'],
  data () {
    return {
      center: { lat: 0, lng: 0 },
      zoom: 18,
      address: '',
      loading: false,
      isCoverageValid: null
    };
  },
  mounted () {
    this.getGeolocalizationCurrent();
  },
  computed: {
    google: gmapApi
  },
  methods: {
    getGeolocalizationCurrent () {
      const instance = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const marker = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          instance.center = marker;
          instance.$refs.gmap.$mapPromise.then(() => {
            var latlng = new this.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            const geocoder = new this.google.maps.Geocoder();
            geocoder.geocode({ latLng: latlng }, (results, status) => {
              if (status === this.google.maps.GeocoderStatus.OK) {
                Object.values(results).map(item => {
                  if (item.geometry.location_type === 'RANGE_INTERPOLATED') {
                    instance.address = item.formatted_address;
                  }
                });
              }
            });
          });
        });
      }
    },
    // showLogin () {
    //   this.$buefy.modal.open({
    //     parent: this,
    //     component: Login,
    //     hasModalCard: true,
    //     trapFocus: true,
    //     canCancel: false
    //   })
    // },
    setValidatePoint () {
      this.loading = true;
      this.center.vendorId = this.vendorId;
      this.$http.post('marketplace/client/coverage/validate', this.center).then(({ data }) => {
        this.loading = false;
        this.isCoverageValid = data.coverageStatus;
        if (!this.isCoverageValid) {
          this.$emit('option', 3);
        } else {
          LocalStorage.remove('tienda_Coverage_client');
          LocalStorage.set('address_Coverage_client', this.address);
          LocalStorage.set('center_Coverage_client', this.center);
          this.$emit('changeAddress', this.address);
        }
      }).catch(() => {
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss">
#actualAddress2{
    // height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: fade 1s ease-in-out;
    .addressPoint{
        display: flex;
        width: 90%;
        height: auto;
        border: 1px solid #CBCBCB;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:5px;
        cursor: pointer;
        .cont-address{
            display: flex;
            align-items: center;
            .address{
                display: flex;
                flex-direction: column;
            }
        }
    }
    .btn-new-address{
        width: 90%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--footerBackground);
        box-shadow: 0px 5px 6px #00000026;
        border-radius: 5px;
        border: solid 1px #324045;
        cursor: pointer;
        margin-top: 10px;
        .text{
            color: var(--footerTextColor);
        }
    }
    .text{
        &.session{
            width: 370px;
            margin: 30px 0 10px 0;
        }
    }
    .close{
        position: absolute;
        right: 25px;
        top: 15px;
    }
    .pointerA {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: var(--buttonBackground);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 5px;
      box-shadow: 0px 3px 6px #000000A3;
      .icon-mks {
          width: 14px !important;
          height: 14px !important;
          background-color: var(--buttonTextColor);
      }
    }
}
</style>
